import {GET_PODCASTS} from "../types";
import auth from '../../utils/auth';
import {getDate} from "../../shared/functions/getDate";
import {loadingAction, notLoadingAction} from "./loadingActions";

export const getPodcastsAction = () => async dispatch => {
    dispatch(loadingAction())
    const {data} = await auth.axiosGet('/articles')
    const podcasts = data.filter(item => item.SoundCloud).map(item => {
        const date = getDate(item)
        return {
            title: item.title,
            description: item.description,
            image: (item.image.formats && item.image.formats.thumbnail && item.image.formats.thumbnail.url) || item.image.url,
            date: date,
            _date: item.published_at,
            theme: item.theme.title,
            themePathname: item.theme.pathname,
            themeID: item.theme.id,
            id: item.id,
            edition: item.edition.title,
            pathname: item.pathname,
            SoundCloud: item.SoundCloud
        }
    })
    dispatch({
        type: GET_PODCASTS,
        payload: {podcasts}
    })
    dispatch(notLoadingAction())
}

