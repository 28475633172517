import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import auth from '../../../utils/auth';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            !process.env.REACT_APP_AUTH || auth.getToken() ? (
                <Component {...props} />
            ) : (
                    <Redirect
                        to={{
                            pathname: 'auth',
                            state: { from: props.location },
                        }}
                    />
            )
        }
    />
);

export default PrivateRoute;
