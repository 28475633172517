import React, { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import Product from "../../components/Product";
import { getProductsAction } from "../../../store/actions/getProductsAction";
import { addOrderAction } from "../../../store/actions/addOrderAction";
import Loader from "../../../shared/UIComponents/Loader";
import Modal from "../../../shared/UIComponents/Modal";
import { ModalComponentConstants } from "../../../shared/UIComponents/Modal/ModalComponentConstants";

import "./ProductsPage.scss";

const ProductsPage = () => {
    const { products } = useSelector((state) => state.products)
    const [product, setProduct] = useState('')
    const loading = useSelector(({ pages }) => pages.isLoading, shallowEqual)
    const dispatch = useDispatch()

    const onClick = (id) => {
        setProduct(id)
    }

    const onClose = (tel) => {
        if (tel) {
            dispatch(addOrderAction({ Phone: tel, Product: product }))
        } else {
            setProduct('')
        }
    }

    useEffect(() => {
        dispatch(getProductsAction())
    }, [dispatch])

    if (loading) {
        return <Loader fullScreen />
    }

    return (
        <div className="container">
            <div className="products--page__name">
                <h3 className="products--page__name--title">Мерч</h3>
            </div>
            <p className="products--page--message">Купуючи нашу фірмову продукцію, ти підтримуєш проєкт духовно та матеріально!</p>
            <div className="products--page">
                {products.map((item) =>
                    <Product
                        onButtonClick={() => onClick(item.id)}
                        key={'product-' + item.id}
                        {...item}
                    />
                )}
            </div>
            <Modal type={ModalComponentConstants.PURCHASE} show={product} onClose={onClose} />
        </div>
    )
}

export default ProductsPage
