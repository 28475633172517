import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {animateScroll} from "react-scroll";

import SearchCard from "../../components/SearchCard";

import './SearchPage.scss';

const SearchPage = () => {
    const location = useLocation()
    const {state} = location
    const searchValue = (state && state.searchValue) || ''
    const list = (state && state.list) || []
    useEffect(() => {
        animateScroll.scrollToTop()
    }, [])
    return(
        <div className='search---page'>
            {searchValue && <h3 className="search--page--title">{searchValue}</h3>}
            <div className="container">
                <div className="search">
                    {searchValue && list.map((item, idx) =>
                        <SearchCard
                            key={'search__card--' + idx}
                            {...item}
                        />)}
                </div>
                {!list.length && <h1 className="search--page--message">По вашому запиту нічого не знайдено!</h1>}
            </div>
        </div>
    )
}

export default SearchPage
