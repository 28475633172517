import React from 'react';

import './ImageRenderer.scss';

const ImageRenderer = ({ src, alt }) => {

    return (
        alt ? <figure className="rendered--image">
            <img src={src.startsWith('http') ? src : `${process.env.REACT_APP_URL}${src}`} alt={alt} className="rendered--image__src" />
            <figcaption className="rendered--image__caption">{alt}</figcaption>
        </figure > : <img src={src.startsWith('http') ? src : `${process.env.REACT_APP_URL}${src}`} alt="" className="rendered--image__src" />
    )
}

export default ImageRenderer
