import React from 'react'

import telegram from '../../../assets/telegram.svg'
import facebook from '../../../assets/facebook.png'
import applePodcast from '../../../assets/apple-podcast.png'
import googlePodcast from '../../../assets/google-podcast.png'

import './SocialNetworks.scss'

const SocialNetworks = () => {
    return (
        <div className="social">
            <div className="container social">
                <h3 className="social__title">ЧИТАЙТЕ ТА СЛУХАЙТЕ</h3>
                <div className="social__networks">
                    <a
                        href="https://www.facebook.com/rozmova.pro"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={facebook} alt="#" className="social__networks--icon"/>
                    </a>
                    <a
                        href="https://t.me/joinchat/VYk36jV7iHrf5PIL"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={telegram} alt="#" className="social__networks--icon"/>
                    </a>
                    <a
                        href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy80NTNkNDJjYy9wb2RjYXN0L3Jzcw=="
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={googlePodcast} alt="#" className="social__networks--icon"/>
                    </a>
                    <a
                        href="https://podcasts.apple.com/ua/podcast/rozmova-pro/id1560619710"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={applePodcast} alt="#" className="social__networks--icon"/>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default SocialNetworks
