import React, { useState } from 'react';
import {NavLink} from "react-router-dom";



const NavbarLink = ({ arr, title, image, pre, handler, content, SoundCloud }) => {
    const [hover, setHover] = useState('')

    if (arr) {
        return (
            <div onMouseEnter={() => setHover(' hover')} onMouseLeave={() => setHover('')} className={'navbar__links--item--block' + hover}>
                <h3 className={"link navbar__links--item nesting "}>{title}</h3>
                <div className="navbar__links--item--hide">
                    {arr.map((item) =>
                        <NavLink
                            onClick={() => {
                                handler()
                                setHover('')
                            }}
                            to={{
                                pathname: pre + item.to,
                                state: {name: item.text, to: item.to, id: item.id}
                            }}
                            key={'nesting-link-' + item.id}
                            className="link navbar__links--item">{item.text}</NavLink>)}
                </div>
            </div>
        )
    }

    return <NavLink to={{pathname: pre, state: {title, image, content, SoundCloud}}} onClick={handler} className="link navbar__links--item">{title}</NavLink>
}

export default NavbarLink
