import React from 'react';

import "./SubscribedMessage.scss";

const SubscribedMessage = (props) => {
    const { onButtonClick } = props

    return (
        <div className="subscribed">
            <p className="subscribed--message">OK!</p>
            <button className="subscribed--btn" onClick={onButtonClick}>Закрити</button>
        </div>
    )
}

export default SubscribedMessage
