import {axiosConfig} from "../config";

export const addOrderAction = (payload) => async dispatch => {
    const token = typeof window !== 'undefined' && process.env.REACT_APP_AUTH ? localStorage.getItem('jwtToken').split('"')[1] : ''

    await axiosConfig.post('/orders', payload, token ? {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    } : null).catch(err => {})

}
