import {GET_LINKS} from "../types";

const initialState = {
    links: [
        {
            title: 'Теми',
            pre: '/tema',
            arr: []
        },
        {
            title: 'Видання',
            pre: '/vydannya',
            arr: []
        },
        {
            title: 'Подкасти',
            pre: '/podkasty'
        },
        {
            title: 'Мерч',
            pre: '/merch'
        }
    ]
}

export const navbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case(GET_LINKS):
            const newMenu = state.links.map(item => {
                if (item.title === 'Теми') {
                    return {
                        ...item,
                        arr: action.payload.themes
                    }
                }
                if (item.title === 'Видання') {
                    return {
                        ...item,
                        arr: action.payload.countries
                    }
                }
                return {
                    ...item
                }
            })
            return {
                ...state,
                links: [...newMenu, ...action.payload.pages]
            }
        default:
            return state
    }
}
