/* eslint-disable no-useless-escape */
import { includes, mapKeys, reject } from 'lodash';
const validateInput = (value, inputValidations = {}) => {
    let errors = [];

    const requiredError = { id: 'components.Input.error.validation.required' };

    mapKeys(inputValidations, (validationValue, validationKey) => {
        switch (validationKey) {
            case 'max':
                if (parseInt(value, 10) > validationValue) {
                    errors.push("Can't be superior");
                }
                break;
            case 'maxLength':
                if (value.length > validationValue) {
                    errors.push('Too long');
                }
                break;
            case 'min':
                if (parseInt(value, 10) < validationValue) {
                    errors.push("Can't be inferior");
                }
                break;
            case 'minLength':
                if (value.length < validationValue) {
                    errors.push('Too short');
                }
                break;
            case 'required':
                if (value.length === 0) {
                    errors.push('The value is required');
                }
                break;
            case 'regex':
                if (!new RegExp(validationValue).test(value)) {
                    errors.push('Does not match');
                }
                break;
            default:
                errors = [];
        }
    });

    if (includes(errors, requiredError)) {
        errors = reject(errors, error => error !== requiredError);
    }

    return errors;
};

export default validateInput;
