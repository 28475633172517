import React from 'react';
import PropTypes from 'prop-types';

import InputPasswordWithErrors from '../InputPasswordWithErrors';
import InputTextWithErrors from '../InputTextWithErrors';

const DefaultInputError = ({ type }) => (
    <div>
        Your input type: <b>{type}</b> does not exist
    </div>
);

const inputs = {
    password: InputPasswordWithErrors,
    text: InputTextWithErrors,
};

function InputsIndex(props) {
    const Input = inputs[props.type] ? inputs[props.type] : DefaultInputError;

    return <Input {...props} value={props.value || ''} />;
}

InputsIndex.defaultProps = {};

InputsIndex.propTypes = {
    type: PropTypes.string.isRequired,
};

export default InputsIndex;
