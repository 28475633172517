import React from 'react';
import { useLocation } from 'react-router-dom';
import {Helmet} from "react-helmet";

import Navbar from "../../components/Navbar";

import auth from '../../../utils/auth';

import "./Header.scss";

const Header = () => {
    const pathname = useLocation().pathname;
    return ((!process.env.REACT_APP_AUTH || auth.getToken() !== null) && ! pathname.startsWith('/auth')) && (
        <>
            <Helmet>
                <title>rozmova.pro | Це наша розмова.про важливе</title>
                <meta
                    name="description"
                    content="Некомерційний проєкт, який ставить собі за мету інтелектуальну протидію популізму шляхом перекладу важливих аналітичних матеріалів авторитетних західних видань."
                />
                <meta property="og:image" content="/logo512.png" />
            </Helmet>
            <Navbar />
        </>
    )
}

export default Header
