import React from 'react'
import {useDispatch} from "react-redux";

import {changeLimitAction} from "../../../store/actions/changeLimitAction";

import './More.scss'

const More = ({style, limit}) => {
    const dispatch = useDispatch()
    const changeLimit = () => {
        dispatch(changeLimitAction(limit + limit))
    }
    return (
        <div className="more" style={style} onClick={changeLimit}>
            <h2 className="more__title">ЩЕ</h2>
        </div>
    )
}

export default More
