import React from 'react';
import {BrowserRouter as Router, StaticRouter} from "react-router-dom";

import Header from "./client/containers/Header";
import Main from "./client/containers/Main";
import Footer from "./client/containers/Footer";

import './App.scss';

const App = (props) => {
    const {ssrLocation} = props;

    return ssrLocation == null ?
        (
            <Router>
                <Header />
                <Main />
                <Footer />
            </Router>
        ) : (
            <StaticRouter location={ssrLocation}>
                <Header />
                <Main />
                <Footer />
            </StaticRouter>
        )
}

export default App;
