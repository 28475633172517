import {LOADING, NOT_LOADING} from "../types";

const initState = {
    isLoading: false
}

export const pageReducer = (state = initState, action) => {
    switch (action.type) {
        case(LOADING):
            return {
                ...state,
                isLoading: true
            }
        case(NOT_LOADING):
            return {
                ...state,
                isLoading: false
            }
        default:
            return state
    }
}
