import React from 'react'
import {NavLink} from "react-router-dom";

import arrow from "../../../assets/arrow-top.png";
import podcast from '../../../assets/podcast.svg';

import './TopCard.scss'

const TopCard = (props) => {
    const {
        elem,
        pathname,
        cardTopStyle,
        cardTopContainerStyle,
        cardTopInfoStyle,
        theme,
        cardTopContainerTitleStyle,
        edition,
        title,
        date,
        _date,
        shortDescription,
        image,
        themePathname,
        SoundCloud
    } = props
    const elemClass = elem ? "top--element" : ""

    const datePath = new Date(_date).toLocaleDateString('UK', {year: 'numeric', month: 'numeric', day: 'numeric'}).replace(/\./g, '-')
    const link = { pathname: '/stattya' + pathname }

    return (
        <div className={"card--top " + elemClass} style={cardTopStyle}>
            <div className="card--top__container" style={cardTopContainerStyle}>
                <div className="card--top__container--info" style={cardTopInfoStyle}>
                    <NavLink
                        className='link'
                        to={{
                            pathname: '/tema' + themePathname
                        }}>
                        <h3 className="card__title">{theme}</h3>
                    </NavLink>
                    <NavLink to={link} className='link'>
                        <h3 className="card--top__container--title" style={cardTopContainerTitleStyle}>{title}</h3>
                    </NavLink>
                    <NavLink to={link} className='card--link'>
                        <h4 className="card__container--edition">{edition}</h4>
                    </NavLink>
                    <NavLink to={{pathname: '/data/' + datePath, state: {date}}} className='link'>
                        <span className="card--top__container--date">{date}</span>
                    </NavLink>
                    <NavLink to={link} className='link'>
                        <p className="card--top__container--description">{shortDescription}</p>
                    </NavLink>
                    <NavLink to={link} className='link'>
                        <img src={arrow} alt="#" className="card--top__container--arrow"/>
                    </NavLink>
                </div>
                <NavLink
                    to={link}
                    className='card--top__container--link card--top__container--image'
                    style={{backgroundImage: `url("${process.env.REACT_APP_URL + image}")`}}
                >
                    {SoundCloud && <span className="card--top__podcasts">
                        <img src={podcast} alt="#" className="card--top__podcasts--item" />
                    </span>}
                </NavLink>
            </div>
        </div>
    )
}

export default TopCard
