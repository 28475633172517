import React, {useEffect} from 'react'
import {animateScroll} from "react-scroll";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import remarkUnwrapImages from "remark-unwrap-images";
import {useSsrState, useSsrEffect} from '@issr/core';

import auth from '../../../utils/auth';
import ImageRenderer from "../../components/ImageRenderer";
import Loader from "../../../shared/UIComponents/Loader";

import './UniversalPage.scss'

const UniversalPage = ({ location }) => {
    const [ page, setPage ] = useSsrState(location.state)

    useSsrEffect(async () => {
        if (!page) {
            await auth.axiosGet(`/pages?pathname=${location.pathname}`).then(res => setPage(res.data[0]))
        }
    })

    useEffect(() => {
        animateScroll.scrollToTop()
    }, [])

    const soundCloud = () => ({ __html: page.SoundCloud })

    return page ? (
        <div className="container">
            <div className="universal--page">
                {page.image && (
                    <img src={process.env.REACT_APP_URL + page.image.url} className="universal--page__image" alt={page.title} />
                )}
                <div className="universal--page__player" dangerouslySetInnerHTML={soundCloud()}></div>
                <ReactMarkdownWithHtml
                    source={page.content}
                    plugins={[ remarkUnwrapImages ]}
                    allowDangerousHtml
                    renderers={{ image: ImageRenderer }}
                />
            </div>
        </div>
    ) : <Loader fullScreen/>
}

export default UniversalPage
