export const GET_LINKS = "GET_LINKS"
export const GET_LINK = "GET_LINK"
export const GET_ARTICLE = "GET_ARTICLE"
export const GET_THEME_ARTICLES = "GET_THEME_ARTICLES"
export const GET_EDITION_ARTICLES = "GET_EDITION_ARTICLES"
export const GET_PODCASTS = "GET_PODCASTS"
export const GET_EDITIONS = "GET_EDITIONS"
export const GET_CARDS = "GET_CARDS"
export const GET_PRODUCTS = "GET_PRODUCTS"

export const CHANGE_LIMIT = "CHANGE_LIMIT"
export const CHANGE_ACTIVE_EDITION = "CHANGE_ACTIVE_EDITION"

export const LOADING = 'LOADING'
export const NOT_LOADING = 'NOT_LOADING'

export const ADD_SUBSCRIBER = 'ADD_SUBSCRIBER'
export const IS_AUTH = 'IS_AUTH'



