import {CHANGE_LIMIT, GET_ARTICLE, GET_CARDS, GET_EDITION_ARTICLES, GET_PODCASTS, GET_THEME_ARTICLES} from "../types";

const initialState = {
    cards: [],
    articles: [],
    podcasts: [],
    editions: [],
    themes: [],
    limit: 9,
    article: {}
}

const sortCards = (action) => {
    const cards = [...action.payload]
    return cards.sort((a, b) => {
        const nowDate = new Date()
        if (nowDate - new Date(a._date) > nowDate - new Date(b._date)) {
            return 1
        } else if(nowDate - new Date(a._date) < nowDate - new Date(b._date)) {
            return -1
        } else {
            return 0
        }
    })
}

export const cardReducer = (state = initialState, action) => {
    switch (action.type) {
        case (GET_CARDS):
            const sortCard = sortCards(action);
            return {
                ...state,
                cards: sortCard
            }
        case (GET_EDITION_ARTICLES):
            const sortEditions = sortCards(action)
            return {
                ...state,
                editions: sortEditions
            }
        case (GET_THEME_ARTICLES):
            const sortThemes = sortCards(action)
            return {
                ...state,
                themes: sortThemes
            }
        case (GET_PODCASTS):
            const podcasts = action.payload.podcasts
            return {
                ...state,
                podcasts: podcasts
            }
        case (GET_ARTICLE):
            const article = action.payload
            return {
                ...state,
                article: article
            }
        case (CHANGE_LIMIT):
            const limit = action.payload
            return {
                ...state,
                limit: limit
            }
        default:
            return state
    }
}
