import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from "react-redux";
import createSsr from '@issr/core';

import createStore from './store/store';
import App from './App';
import './index.scss';

const SSR = createSsr();
const { store } = createStore({
    initState: window.REDUX_DATA
});

hydrate(
    <React.StrictMode>
        <SSR>
            <Provider store={store}>
                <App />
            </Provider>
        </SSR>
    </React.StrictMode>,
    document.getElementById('root')
);
