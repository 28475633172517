import React from 'react';

import './Product.scss';

const Product = (props) => {
    const {
        title,
        image,
        price,
        onButtonClick
    } = props

    return (
        <div className="product">
            <img src={process.env.REACT_APP_URL + image} alt={title} className="product--image" />
            <h3 className="product--title">{title}</h3>
            <h3 className="product--price">{price + ' \u20B4'}</h3>
            <button onClick={onButtonClick} className="product--purchase">Купити</button>
        </div>
    )
}

export default Product
