import React, {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom'

import './ScrollBar.scss'

const ScrollBar = () => {
    const [scrolled, setScrolled] = useState(0)
    const {pathname} = useLocation()

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const winScroll = document.documentElement.scrollTop;
            const height = document.documentElement.scrollHeight - window.innerHeight;
            setScrolled((winScroll / height) * 100)
        })
    }, [scrolled])

    return (pathname.includes('/stattya') || pathname.includes('/pro-rozmovi')) &&
        <div className="scrollbar">
            <div className="scrollbar__container">
                <div className="scrollbar__progress--bar" style={{width: scrolled + "%"}} />
            </div>
        </div>
}

export default ScrollBar
