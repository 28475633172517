import React from 'react'
import {NavLink, useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";

import {changeActiveEdition} from "../../../store/actions/changeActiveEditionAction";
import './SearchCard.scss'

const SearchCard = (props) => {
    const {
        shortDescription,
        pathname,
        theme,
        themePathname,
        title,
        date,
        _date,
        edition,
        editionID,
        editionPathname,
        countryID,
        countryPathname,
    } = props

    const location = useLocation()
    const dispatch = useDispatch()
    const valueReg = location.state.searchValue.toLowerCase().replace(/\s/g, '|')

    const markTitle = title.split(' ')
    const newTitle = markTitle.map((item, idx) => {
        if (item.toLowerCase().search(valueReg) + 1) {
            return <mark className="search__card--mark" key={item + "-" + idx}>{item} </mark>
        }
        return ` ${item} `
    })

    const markDescription = shortDescription.split(' ')
    const newDescription = markDescription.map((item, idx) => {
        if (item.toLowerCase().search(valueReg) + 1) {
            return <mark className="search__card--mark" key={item + "-" + idx}>{item}</mark>
        }
        return ` ${item} `
    })

    const datePath = new Date(_date).toLocaleDateString('UK', {year: 'numeric', month: 'numeric', day: 'numeric'}).replace(/\./g, '-')

    return(
        <div className="search__card">
            <div className="search__card--info">
                <NavLink
                    className='search__card--link link'
                    to={{
                        pathname: '/tema' + themePathname
                    }}>
                    <h4 className="search__card--info--theme">{theme}</h4>
                </NavLink>
                <NavLink to={{pathname: '/data/' + datePath, state: {date}}} className='search__card--link link'>
                    <h5 className="search__card--info--date">{date}</h5>
                </NavLink>
                <NavLink
                    onClick={() => {
                        dispatch(changeActiveEdition({regionID: countryID, editionID: editionID}))
                    }}
                    to={{pathname: '/vydannya' + countryPathname + editionPathname, state: {name: editionID, id: countryID}}}
                    className='search__card--link link'>
                    <h4 className="search__card--info--edition">{edition}</h4>
                </NavLink>
            </div>
            <NavLink
                to={{pathname: '/stattya' + pathname}}
                className='search__card--link link'
            >
                <h3 className="search__card--title">{newTitle}</h3>
                <p className="search__card--description">{newDescription}</p>
            </NavLink>
        </div>
    )
}

export default SearchCard
