import {GET_EDITIONS} from "../types";
import auth from '../../utils/auth';
import {loadingAction} from "./loadingActions";
import {notLoadingAction} from "./loadingActions";


export const getEditionsActions = (payload) => async dispatch => {
    dispatch(loadingAction)
    // const {data} = await auth.axiosGet(`/regions/${payload}`)
    const {data: DATA} = await auth.axiosGet('/regions')
    // const country = data.pathname.split('/')[1]
    // const editions = {
    //     nameCountry: data.countryName,
    //     _nameCountry: country,
    //     flag: data.flag.url,
    //     editions: data.editions,
    //     pathname: data.pathname,
    // }
    const editions = DATA.map(item => {
        const country = item.pathname.split('/')[1]
        return {
            nameCountry: item.countryName,
            _nameCountry: country,
            flag: item.flag.url,
            id: item.id,
            edition: [{
                title: 'Усі Видання',
                pathname: '/' + country
            }, ...item.editions],
            pathname: item.pathname,
        }
    })

    dispatch({
        type: GET_EDITIONS,
        payload: editions,
    })
    dispatch(notLoadingAction)
}
