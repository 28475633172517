import React, {useEffect, useState} from 'react';
import { NavLink, useLocation } from "react-router-dom";

import './Logo.scss';

const Logo = ({ classNameTitle, to = '/', classNameLink = '' }) => {
    const [property, setProperty] = useState('none')
    const logoClass = {
        animationName: property
    }
    const {pathname} = useLocation()

    useEffect(() => {
        if ('none' === property && (pathname === '/'
            || pathname.includes('/tema')
            || pathname.includes('/vydannya')
            || pathname.includes('/podkasty')
            || pathname.includes('/merch'))) {
            const randomInterval = Math.floor(Math.random() * 15000) + 31001
            setTimeout(() => setProperty('App-logo-spin'), randomInterval)
        }
        setTimeout(() => setProperty('none'), 1001)
    }, [property, setProperty, pathname])

    if (pathname === '/') {
        return <h1 className = "logo">розм<span style = { logoClass } className = {'logo--image ' + classNameTitle} /> ва.про</h1 >
    }
    return (
        <NavLink to={to} className={'link ' + classNameLink}>
            <h1 className="logo">розм<span style={logoClass} className={'logo--image ' + classNameTitle} />ва.про</h1>
        </NavLink >
    )
}

export default Logo
