import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch , useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

import {getLinksAction} from "../../../store/actions/getLinksAction";
import {getCardAction} from "../../../store/actions/getCardsAction";
import NavbarLink from "../NavbarLink";
import Logo from "../../../shared/UIComponents/Logo";
import Search from "../Search";

import './Navbar.scss';

const Navbar = () => {
    const navHeight = 98
    const token = useSelector(({auth}) => auth.isAuth, shallowEqual)
    const cards = useSelector(({cards}) => cards.cards, shallowEqual)
    const links = useSelector(({navbar}) => navbar.links, shallowEqual)
    const [burger, setBurger] = useState(false)
    const burgerActiveClass = burger ? "active" : ""

    const [scrolled, setScrolled] = useState(navHeight)
    const [height, setHeight] = useState(navHeight)
    const [show, setShow] = useState(false)

    const {pathname} = useLocation()
    const checkPage = pathname.includes('/stattya') || pathname.includes('/pro-rozmovi')
    const dispatch = useDispatch()

    useEffect(() => {
        if ((!process.env.REACT_APP_AUTH || token) && !cards.length) {
            dispatch(getLinksAction())
            dispatch(getCardAction())
        }
    })

    useEffect(() => {
        if (checkPage) {
            window.addEventListener('scroll', function() {

                const st = this.scrollY;
                if(Math.ceil(window.scrollY + window.innerHeight) >= document.documentElement.offsetHeight) {
                    setHeight(navHeight)
                    setShow(true)
                } else {
                    setShow(false)
                }
                setScrolled(st)
            })
        }
    }, [scrolled, checkPage])
    const navbarClass = !checkPage || show ? 'fixed' : ''
    const navbarStyle = {
        height: `${height}px`,
        padding: '18px 0 30px',
        overflow: 'visible'
    }
    return(
        <nav
            className={"navbar " + navbarClass}
            style={navbarStyle}
            onMouseUp={() => setHeight(navHeight)}
        >
            <div className="container navbar__container">
                <Logo />
                <div className="navbar__control" style={{height: !burger ? '0' : '100%', padding: !burger && 0}}>
                    <div className="navbar__links">
                        {links.map((item) => <NavbarLink key={item.pre} handler={() => setBurger(!burger)} {...item} />)}
                    </div>
                    <Search />
                </div>
                <div
                    onClick={() => setBurger(!burger)}
                    className={"navbar__container--burger " + burgerActiveClass}
                >
                    <span className="navbar__container--burger--item"/>
                    <span className="navbar__container--burger--item"/>
                    <span className="navbar__container--burger--item"/>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
