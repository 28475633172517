import {axiosConfig} from "../config";

export const addSubscriberAction = (payload) => async dispatch => {
    const token = typeof window !== 'undefined' && process.env.REACT_APP_AUTH ? localStorage.getItem('jwtToken').split('"')[1] : ''

    await axiosConfig.post('/subscribers', {
        email: payload,
        active: true
    }, token ? {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    } : null).catch(err => {})

}
