import React from 'react';
import {NavLink} from "react-router-dom";

import TopCard from "../TopCard";
import EditionCard from "../EditionCard";
import podcast from '../../../assets/podcast.svg';

import './Card.scss';


const Card = (props) => {
    const {
        top,
        theme,
        themePathname,
        title,
        edition,
        date,
        _date,
        shortDescription,
        thumbnail,
        image,
        elem,
        editionTop,
        pathname,
        SoundCloud,
        limit = true
    } = props
    const link = { pathname: '/stattya' + pathname }

    if (editionTop && editionTop.pathname !== '/all') {
        return <EditionCard editionTop={editionTop} {...props} />
    }

    if (top) {
        return <TopCard elem={elem} {...props} />
    }

    if (limit) {
        const datePath = new Date(_date).toLocaleDateString('UK', { year: 'numeric', month: 'numeric', day: 'numeric' }).replace(/\./g, '-')
        const imgSrc = process.env.REACT_APP_URL + image
        const imgContainerStyle = {
            backgroundImage: 'url(' + imgSrc + ')',
            backgroundPosition: '50%',
            backgroundSize: 'cover'
        }
        return (
            <div className="card">
                <NavLink
                    className="link"
                    to={{
                    pathname: '/tema' + themePathname
                }}>
                    <h3 className="card__title">{theme}</h3>
                </NavLink>
                <div className="card__container">
                    {SoundCloud && <NavLink
                        to={link}
                        className="card__podcasts"
                    >
                        <img src={podcast} alt="#" className="card__podcasts--item" />
                    </NavLink>}
                    <NavLink
                        to={link}
                        className='card--link'
                    >
                        {thumbnail ?
                            <img src={imgSrc} alt={title} className="card__container--image" />
                            :
                            <div className="card__container--image__container" style={imgContainerStyle}></div>
                        }
                        <h3 className="card__container--title">{title}</h3>
                    </NavLink>
                    <NavLink
                        to={link}
                        className='card--link'>
                        <h4 className="card__container--edition">{edition}</h4>
                    </NavLink>
                    <NavLink
                        to={{pathname: '/data/' + datePath, state: {date}}}
                        className='card--link'
                    >
                        <span className="card__container--date">{date}</span>
                    </NavLink>
                    <NavLink
                        to={link}
                        className='card--link'
                    >
                        <p className="card__container--description">{shortDescription}</p>
                    </NavLink>
                </div>
            </div>
        )
    }

    return null
}

export default Card
