import {GET_THEME_ARTICLES} from "../types";
import auth from '../../utils/auth';
import {getDate} from "../../shared/functions/getDate";
import {loadingAction, notLoadingAction} from "./loadingActions";

export const getThemeArticlesAction = (payload) => async dispatch => {
    dispatch(loadingAction())
    const { data } = await auth.axiosGet(`/headings?pathname=${payload}`)
    const articles = data[0].articles.map(item => {
        let shortDescription;

        const clearDescriptionOfTitles = item.content.replace(/#+.+?$/gm, '')
        const clearDescriptionOfImage = clearDescriptionOfTitles.replace(/!\[data:image.+?\)/gm, '')
        const [...descriptionArr] = clearDescriptionOfImage.split('')
        if (clearDescriptionOfImage.length > 230) {
            shortDescription = descriptionArr.map((item, idx) => idx + 1 <= 130 ? item : null).join('') + '...'
        } else {
            shortDescription = [...descriptionArr]
        }
        const date = getDate(item)

        return {
            title: item.title,
            description: item.content,
            image: (item.image.formats && item.image.formats.thumbnail && item.image.formats.thumbnail.url) || item.image.url,
            date: date,
            _date: item.updatedAt,
            top: item.top,
            id: item.id,
            pathname: item.pathname,
            shortDescription,
            theme: data[0].title,
            editionId: item.edition
        }
    })

    dispatch({
        type: GET_THEME_ARTICLES,
        payload: articles
    })
    dispatch(notLoadingAction())
}
