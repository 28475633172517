import {GET_ARTICLE} from "../types";
import auth from '../../utils/auth';
import removeMd from "remove-markdown";
import {getDate} from "../../shared/functions/getDate";
import {loadingAction, notLoadingAction} from "./loadingActions";

export const getArticleAction = (payload) => async dispatch => {
    dispatch(loadingAction())
    const res = await auth.axiosGet(`/articles?pathname=${payload}`)
    const data = res.data[0]

    let shortDescription
    const clearDescriptionOfImage = removeMd(data.content)
    const [...descriptionArr] = clearDescriptionOfImage.split('')
    if (data.ShortDescription) {
        shortDescription = data.ShortDescription
    } else if (clearDescriptionOfImage.length > 230) {
        shortDescription = descriptionArr.map((item, idx) => idx + 1 <= 130 ? item : null).join('') + '...'
    } else {
        shortDescription = descriptionArr.join('')
    }
    
    dispatch({
        type: GET_ARTICLE,
        payload: {
            author: data.author.authorName,
            authorPathname: data.author.pathname,
            authorDescription: data.author.Description,
            authorImage: data.author.Picture && (
                (
                    data.author.Picture.formats
                    && data.author.Picture.formats.real_square_thumbnail
                    && data.author.Picture.formats.real_square_thumbnail.url
                ) || (
                    data.author.Picture.formats
                    && data.author.Picture.formats.square_thumbnail
                    && data.author.Picture.formats.square_thumbnail.url
                ) || data.author.Picture.url
            ),
            country: data.country.countryName,
            countryPathname: data.country.pathname,
            countryID: data.country.id,
            content: data.content,
            edition: data.edition.title,
            editionPathname: data.edition.pathname,
            editionID: data.edition.id,
            editionLogo: data.edition.logo && ((
                data.edition.logo.formats
                && data.edition.logo.formats.square_thumbnail
                && data.edition.logo.formats.square_thumbnail.url
            ) || data.edition.logo.url),
            image: data.image && ((
                data.image.formats
                && data.image.formats.featured_image
                && data.image.formats.featured_image.url
            ) || data.image.url),
            theme: data.theme.title,
            link: data.link,
            title: data.title,
            date: getDate(data),
            translator: data.translator ? data.translator.translatorName : '',
            SoundCloud: data.SoundCloud,
            shortDescription: shortDescription,
            _data: data.published_at || data.createdAt
        }
    })
    dispatch(notLoadingAction())
}
