import React, { useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';

import "./PurchaseForm.scss";

const PurchaseForm = (props) => {
    const { onButtonClick } = props
    const [res, setRes] = useState(false)
    const [tel, setTel] = useState()
    const [err, setErr] = useState(false)

    const onSubmit = (e) => {
        e.preventDefault()
        if (res) {
            setRes(false)
            onButtonClick()
        } else if (tel && isValidPhoneNumber(tel)) {
            setErr(false)
            onButtonClick(tel)
            setRes(true)
        } else {
            setErr(true)
        }
    }

    return (
        <form className="purchase" onSubmit={onSubmit}>
            {!res && <PhoneInput
                country="UA"
                international
                withCountryCallingCode
                value={tel}
                onChange={setTel}
                className="purchase--field"
                required
            />}
            {err && (
                <p className="purchase--error">
                    * Будь ласка, вкажіть номер телефону у форматі <span className="purchase--error--format">+380 xx xxx xxxx.</span>
                </p>
            )}
            <p className="purchase--message">
                {res
                    ? "Дякуємо! На зв'язку!"
                    : 'Дуже скоро Вам зателефонує наш менеджер щодо найменування, кількості, способу доставки та оплати' +
                    'нашого мега-мерчу.'
                }
            </p>
            <button type='submit' className="purchase--btn">{res ? 'OK' : 'Відправити'}</button>
        </form>
    )
}

export default PurchaseForm
