import React from 'react'
import {shallowEqual, useSelector} from "react-redux";

import SubscribeForm from "../../components/SubscribeForm";
import SocialNetworks from "../../components/SocialNetworks";
import Copyright from "../../components/Copyright";

import './Footer.scss'

const Footer = () => {
    const token = useSelector(({auth}) => auth.isAuth, shallowEqual)

    return (!process.env.REACT_APP_AUTH || token) && (
        <div className="footer">
            <SubscribeForm />
            <SocialNetworks />
            <Copyright />
        </div>
    )
}

export default Footer
