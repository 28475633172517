import {CHANGE_ACTIVE_EDITION, GET_EDITIONS} from "../types";

const initialState = {
    editions: []
}
export const listsReducer = (state = initialState, action) => {
    if (action.type === GET_EDITIONS) {
        return {
            ...state,
            editions: action.payload
        }
    } else if (action.type === CHANGE_ACTIVE_EDITION) {
        const searchRegion = state.editions.map((item => {
            if (item.id === action.payload.regionID) {
                return {
                    ...item,
                    edition: item.edition
                }
            }
            return item
        }))

        return {
            ...state,
            editions: searchRegion
        }
    }
    
    return state
}
