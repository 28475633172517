import React, {useState} from 'react'
import './SubscribeForm.scss'
import {useDispatch} from "react-redux";
import {addSubscriberAction} from "../../../store/actions/addSubscriberAction";
import Modal from "../../../shared/UIComponents/Modal";
import { ModalComponentConstants } from "../../../shared/UIComponents/Modal/ModalComponentConstants";

const SubscribeForm = () => {
    const dispatch = useDispatch()
    const [value, setValue] = useState('')
    const [err, setErr] = useState(false)
    const [show, setShow] = useState(false)
    const emailRegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/

    const onSubmit = (e) => {
        e.preventDefault()
        const checkEmail = emailRegExp.exec(value)
        if (checkEmail) {
            setErr(false)
            dispatch(addSubscriberAction(value))
            setValue('')
            setShow(true)
        } else {
            setErr(true)
        }
    }

    const onClose = () => {
        setShow(false)
    }

    return (
        <div className="subscribe">
            <div className="container">
                <h3 className="subscribe__title">Щоп'ятниці ми будемо надсилати Вам добірку найкращих статей:</h3>
                <form className="subscribe__form" onSubmit={onSubmit}>
                    <div className="subscribe__form--block">
                        <input type='text' onChange={({target}) => setValue(target.value)} value={value} className="subscribe__form--field" placeholder='на@вашу.пошту' />
                        {err && <span className="subscribe__form--error--text">* Будь ласка, вкажіть вірний email.</span>}
                    </div>
                    <button type='submit' className="subscribe__form--btn">Підписатись</button>
                </form>
            </div>
            <Modal type={ModalComponentConstants.SUBSCRIBE} show={show} onClose={onClose} />
        </div>
    )
}

export default SubscribeForm
