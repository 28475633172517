import React from "react";

import SubscribedMessage from "../../../client/components/SubscribedMessage";
import PurchaseForm from "../../../client/components/PurchaseForm";

import "./Modal.scss";

const Modal = (props) => {
    const { type, show, onClose } = props

    return show ? (
        <div className="modal">
            <div className="modal__background" onClick={onClose} />
            <div className="modal__block">
                {
                    {
                        'SUBSCRIBE': <SubscribedMessage onButtonClick={onClose} />,
                        'PURCHASE': <PurchaseForm onButtonClick={onClose} />
                    }[type]
                }
            </div>
        </div>
    ) : null
}

export default Modal
