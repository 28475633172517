/* eslint-disable no-mixed-operators */
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isFunction } from 'lodash';
import cn from 'classnames';

import Label from '../Label';
import InputDescription from '../InputDescription';
import InputErrors from '../InputErrors';
import InputPassword from '../InputPassword';

import validateInput from '../../../utils/inputsValidations';

// import './styles.css';

class InputPasswordWithErrors extends React.Component {
    state = { errors: [], hasInitialValue: false };

    componentDidMount() {
        const { value, errors } = this.props;

        if (!isEmpty(value)) {
            this.setState({ hasInitialValue: true });
        }

        if (!isEmpty(errors)) {
            this.setState({ errors });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!isEmpty(nextProps.value) && !this.state.hasInitialValue) {
            this.setState({ hasInitialValue: true });
        }

        if (nextProps.didCheckErrors !== this.props.didCheckErrors) {
            const errors = isEmpty(nextProps.errors) ? [] : nextProps.errors;
            this.setState({ errors });
        }
    }

    handleBlur = ({ target }) => {
        if (!isEmpty(target.value) || this.state.hasInitialValue) {
            const errors = validateInput(target.value, this.props.validations);
            this.setState({ errors, hasInitialValue: true });
        }
    };

    render() {
        const {
            autoFocus,
            deactivateErrorHighlight,
            disabled,
            errorsClassName,
            errorsStyle,
            inputClassName,
            inputDescriptionClassName,
            inputDescriptionStyle,
            inputStyle,
            labelClassName,
            labelStyle,
            name,
            onChange,
            onFocus,
            placeholder,
            style,
            tabIndex,
            value,
        } = this.props;
        const handleBlur = isFunction(this.props.onBlur)
            ? this.props.onBlur
            : this.handleBlur;

        return (
            <div
                className={cn(
                    'inputPasswordWithErrors',
                    'col-md-12',
                    !isEmpty(this.props.className) && this.props.className
                )}
                style={style}
            >
                <Label
                    className={labelClassName}
                    htmlFor={name}
                    message={this.props.label}
                    style={labelStyle}
                />
                <InputPassword
                    autoFocus={autoFocus}
                    className={inputClassName}
                    disabled={disabled}
                    deactivateErrorHighlight={deactivateErrorHighlight}
                    error={!isEmpty(this.state.errors)}
                    name={name}
                    onBlur={handleBlur}
                    onChange={onChange}
                    onFocus={onFocus}
                    placeholder={placeholder}
                    style={inputStyle}
                    tabIndex={tabIndex}
                    value={value}
                />
                <InputDescription
                    className={inputDescriptionClassName}
                    message={this.props.inputDescription}
                    style={inputDescriptionStyle}
                />
                <InputErrors
                    className={errorsClassName}
                    errors={this.state.errors}
                    style={errorsStyle}
                />
            </div>
        );
    }
}

InputPasswordWithErrors.defaultProps = {
    autoFocus: false,
    className: '',
    deactivateErrorHighlight: false,
    didCheckErrors: false,
    disabled: false,
    onBlur: false,
    onFocus: () => { },
    errors: [],
    errorsClassName: '',
    errorsStyle: {},
    inputClassName: '',
    inputDescription: '',
    inputDescriptionClassName: '',
    inputDescriptionStyle: {},
    inputStyle: {},
    label: '',
    labelClassName: '',
    labelStyle: {},
    placeholder: '',
    style: {},
    tabIndex: '0',
    validations: {},
};

InputPasswordWithErrors.propTypes = {
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    deactivateErrorHighlight: PropTypes.bool,
    didCheckErrors: PropTypes.bool,
    disabled: PropTypes.bool,
    errors: PropTypes.array,
    errorsClassName: PropTypes.string,
    errorsStyle: PropTypes.object,
    inputClassName: PropTypes.string,
    inputDescription: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.shape({
            id: PropTypes.string,
            params: PropTypes.object,
        }),
    ]),
    inputDescriptionClassName: PropTypes.string,
    inputDescriptionStyle: PropTypes.object,
    inputStyle: PropTypes.object,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.shape({
            id: PropTypes.string,
            params: PropTypes.object,
        }),
    ]),
    labelClassName: PropTypes.string,
    labelStyle: PropTypes.object,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    style: PropTypes.object,
    tabIndex: PropTypes.string,
    validations: PropTypes.object,
    value: PropTypes.string.isRequired,
};

export default InputPasswordWithErrors;
