import React from 'react';
import {shallowEqual, useSelector} from "react-redux";
import {Switch, Route} from "react-router-dom";

import AuthPage from '../../pages/AuthPage';
import AboutConversationsPage from "../../pages/AboutСonversationsPage";
import CardPage from "../../pages/CardPage";
import EditionPage from "../../pages/EditionPage";
import ThemePage from "../../pages/ThemePage";
import PodcastPage from "../../pages/PodcastPage";
import ProductsPage from "../../pages/ProductsPage";

import ScrollBar from "../../../shared/UIComponents/ScrollBar";
import DateArticlesPage from "../../pages/DateArticlesPage";
import SearchPage from "../../pages/SearchPage";
import AuthorArticlesPage from "../../pages/AuthorArticlePage";
import UniversalPage from "../../pages/UniversalPage";

import PrivateRoute from '../../containers/PrivateRoute';
import './Main.scss';

const Main = () => {
    const {links} = useSelector(({navbar}) => navbar,shallowEqual)
    return (
        <>
            <ScrollBar />
            <div className="main">
                <Switch>
                    {process.env.REACT_APP_AUTH && <Route path="/auth/:logout?" component={AuthPage} />}
                    <PrivateRoute exact path='/' component={AboutConversationsPage} />
                    <PrivateRoute exact path='/stattya/:slug' component={CardPage} />

                    <PrivateRoute exact path='/tema/:name' component={ThemePage} />
                    <PrivateRoute exact path={'/vydannya/:country/:edition?'}>
                        {(props) => {
                            return <EditionPage {...props.location.state} />
                        }}
                    </PrivateRoute>
                    <PrivateRoute exact path='/podkasty'>
                        <PodcastPage />
                    </PrivateRoute>
                    <PrivateRoute exact path='/merch'>
                        <ProductsPage />
                    </PrivateRoute>
                    <PrivateRoute exact path='/data/:date'>
                        {(props) => {
                            return <DateArticlesPage  {...props.location.state} />
                        }}
                    </PrivateRoute>
                    <PrivateRoute exact path='/search'>
                        {(props) => {
                            return <SearchPage {...props.location.state} />
                        }}
                    </PrivateRoute>
                    <PrivateRoute exact path='/avtor/:author'>
                        {(props) => {
                            return <AuthorArticlesPage {...props.location.state} />
                        }}
                    </PrivateRoute>
                    {links.map((item, idx) =>
                        item.static && <PrivateRoute key={'page-' + idx} exact path={item.pre} component={UniversalPage} />)}
                    <PrivateRoute path='/:slug'>
                        {(props) => {
                            return <UniversalPage {...props} />
                        }}
                    </PrivateRoute>
                </Switch>
            </div>
        </>
    )
}

export default Main
