import React, {memo, useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {animateScroll} from "react-scroll";

import {getThemeArticlesAction} from "../../../store/actions/getThemeArticlesAction";
import { getEditionsActions } from '../../../store/actions/getEditionsAction';
import Card from "../../components/Card";
import More from "../../../shared/UIComponents/More";
import Loader from "../../../shared/UIComponents/Loader";

import './ThemePage.scss'

const ThemePage = ({location}) => {
    const dispatch = useDispatch()
    const {pathname} = location
    const width = typeof window !== 'undefined' ? window.innerWidth : 0
    const {editions} = useSelector(({lists}) => lists, shallowEqual)
    const cards = useSelector(({cards}) => cards.themes, shallowEqual)
    const limit = useSelector(({cards}) => cards.limit, shallowEqual)
    const loading = useSelector(({pages}) => pages.isLoading, shallowEqual)
    const edition = []
    
    useEffect(() => {
        dispatch(getThemeArticlesAction(pathname.substring(5)))
        dispatch(getEditionsActions())
    }, [dispatch, pathname])

    useEffect(() => {
        animateScroll.scrollToTop()
    }, [])

    editions.map(item => edition.push(...item.edition))

    if (loading) {
        return <Loader fullScreen/>
    }

    return cards.length ? (
        <div className="container">
            <div className="theme--page">
                <div className="theme--page__name">
                    <h3 className="theme--page__name--title">{cards[0].theme}</h3>
                </div>
                <div className="theme--page__list">
                    {cards.map((item, idx) => {
                        const publication = edition.find(element => element.id === item.editionId)
                        return (
                            <Card
                                limit={idx + 1 < limit}
                                cardTopStyle={{gridColumn: width > 768 ? '3' : '1', gridRow: width > 768 ? '1/3' : '3', marginTop: '10px', height: 'min-content'}}
                                cardTopContainerStyle={{flexDirection: 'column-reverse'}}
                                cardTopInfoStyle={{textAlign: 'center', padding: width <= '1024' ? "20px" : "40px"}}
                                cardTopContainerTitleStyle={{fontSize: '24px'}}
                                elem
                                id={'card-' + item.id}
                                key={'card-' + item.id}
                                edition={publication ? publication.title : ''}
                                {...item}
                            />
                        )
                    })}
                    {cards.length > 9 && (cards.length > limit) && <More limit={limit} />}
                </div>
            </div>
        </div>
    ) : <></>
}

export default memo(ThemePage)
