import { axiosConfig } from "../store/config";
import { isEmpty } from 'lodash';

const TOKEN_KEY = 'jwtToken';
const USER_INFO = 'userInfo';

const parse = JSON.parse;
const stringify = JSON.stringify;

const auth = {
    clear(key) {
        if (typeof window !== 'undefined' && localStorage && localStorage.getItem(key)) {
            return localStorage.removeItem(key);
        }

        return null;
    },

    clearAppStorage() {
        if (typeof window !== 'undefined' && localStorage) {
            localStorage.clear();
        }
    },

    clearToken(tokenKey = TOKEN_KEY) {
        return auth.clear(tokenKey);
    },

    clearUserInfo(userInfo = USER_INFO) {
        return auth.clear(userInfo);
    },

    get(key) {
        if (typeof window !== 'undefined' && localStorage && localStorage.getItem(key)) {
            return parse(typeof window !== 'undefined' && localStorage.getItem(key)) || null;
        }

        return null;
    },

    getToken(tokenKey = TOKEN_KEY) {
        return auth.get(tokenKey);
    },

    getUserInfo(userInfo = USER_INFO) {
        return auth.get(userInfo);
    },

    set(value, key) {
        if (isEmpty(value)) {
            return null;
        }

        if (typeof window !== 'undefined' && localStorage) {
            return localStorage.setItem(key, stringify(value));
        }

        return null;
    },

    setToken(value = '', tokenKey = TOKEN_KEY) {
        return auth.set(value, tokenKey);
    },

    setUserInfo(value = '', userInfo = USER_INFO) {
        return auth.set(value, userInfo);
    },

    axiosGet( url ) {
        const token = auth.getToken();
        return token ? axiosConfig(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }) : axiosConfig(url);
    }
};

export default auth;
