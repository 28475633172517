import { GET_PRODUCTS } from "../types";
import auth from '../../utils/auth';
import { loadingAction, notLoadingAction } from "./loadingActions";

const createObject = (item) => ({
    title: item.Title,
    image: item.Image.url,
    price: item.Price,
    id: item.id
})

export const getProductsAction = () => async dispatch => {
    dispatch(loadingAction())
    const { data } = await auth.axiosGet('/products')
    const products = data.map(item => {
        return item && createObject(item)
    })

    dispatch({
        type: GET_PRODUCTS,
        payload: products
    })
    dispatch(notLoadingAction())
}