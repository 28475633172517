import React from 'react'
import ReactMarkdownWithHtml from "react-markdown/with-html";
import './EditionCard.scss'

const EditionCard = (props) => {
    const {
        cardTopStyle,
        cardTopContainerStyle,
        flag,
        editionTop
    } = props

    const flagSrc = flag.includes('http') ? flag : process.env.REACT_APP_URL + flag

    return (
        <div className={"card--edition"} style={cardTopStyle}>
            <div className="card--edition__container" style={cardTopContainerStyle}>
                <img src={process.env.REACT_APP_URL + ((editionTop.logo && editionTop.logo.formats && editionTop.logo.formats.square_thumbnail && editionTop.logo.formats.square_thumbnail.url) || editionTop.logo.url)} alt="#" className="card--edition__container--logo"/>
                <h3 className="card--edition__container--title">{editionTop.title}</h3>
                <div className="card--edition__container--description">
                    <ReactMarkdownWithHtml source={editionTop.description} allowDangerousHtml transformImageUri={uri =>
                        uri.startsWith("http") ? uri : `${process.env.REACT_APP_URL}${uri}`
                    } />
                </div>
                <a href={editionTop.link} rel="noreferrer" target='_blank' className="card--edition__container--link">{editionTop.link.replace(/(.+:\/\/)?(.+?)\/?$/, '$2')}</a>
            </div>
            <img src={flagSrc} alt="#" className="card--edition__flag"/>
        </div>
    )
}

export default EditionCard
