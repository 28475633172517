import React from 'react';
import PropTypes from 'prop-types';
import { findIndex, get, map, replace } from 'lodash';
import { connect } from "react-redux";

import Input from '../../../shared/UIComponents/InputsIndex';
import auth from '../../../utils/auth';
import request from '../../../utils/request';
import {authAction} from "../../../store/actions/authAction";

import './styles.css';


class AuthPage extends React.Component {
    state = { value: {}, errors: [], didCheckErrors: false };
    componentDidMount() {
        this.generateForm();
    }

    componentWillReceiveProps() {
        this.generateForm();
    }

    generateForm = () => {
        const value = {
            identifier: '',
            password: ''
        };
        this.setState({ value });
    };

    handleChange = ({ target }) =>
        this.setState({
            value: { ...this.state.value, [target.name]: target.value },
        });

    handleSubmit = e => {
        e.preventDefault();
        request(process.env.REACT_APP_URL + '/auth/local', { method: 'POST', body: this.state.value })
            .then(response => {
                auth.setToken(response.jwt);
                auth.setUserInfo(response.user);
                this.props.dispatchToken(response.jwt)
                this.redirectUser();
            })
            .catch(err => {
                const errors = [
                    { name: 'identifier', errors: map(err.response.payload.message[0].messages, 'message') },
                ];
                this.setState({ didCheckErrors: !this.state.didCheckErrors, errors });
            });
    };

    redirectUser = () => {
        this.props.history.push('/');
    };

    render() {
        const inputs = [
            {
                label: 'Username',
                name: 'identifier',
                type: 'text',
                placeholder: 'johndoe@gmail.com'
            },
            {
                label: 'Password',
                name: 'password',
                type: 'password'
            }
        ];
        if (this.props.match.params.logout === 'logout') {
            auth.clearAppStorage();
            this.props.history.push('/auth');
            return null;
        }
        const token = auth.getToken()
        if (token) {
            this.props.history.push('/');
            return null;
        }
        return (
            <div className="authPage">
                <div className="wrapper">
                    <div className="formContainer" style={{ marginTop: '.9rem' }}>
                        <div className="container-fluid">
                            <form onSubmit={this.handleSubmit}>
                                <div className="row" style={{ textAlign: 'start' }}>
                                    {map(inputs, (input, key) => (
                                        <Input
                                            autoFocus={key === 0}
                                            didCheckErrors={this.state.didCheckErrors}
                                            errors={get(
                                                this.state.errors,
                                                [
                                                    findIndex(this.state.errors, ['name', input.name]),
                                                    'errors',
                                                ],
                                                []
                                            )}
                                            key={input.name}
                                            label={input.label}
                                            name={input.name}
                                            onChange={this.handleChange}
                                            placeholder={get(input, 'placeholder')}
                                            type={input.type}
                                            validations={{ required: true }}
                                            value={get(this.state.value, input.name, '')}
                                        />
                                    ))}
                                    <div className="col-md-12 buttonContainer">
                                        <button className="button primary" style={{ width: '100%' }} type="submit">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// const AuthPage = () => {
//
//     return (
//         <div className="authPage">
//             <div className="wrapper">
//                 <div className="formContainer" style={{ marginTop: '.9rem' }}>
//                     <div className="container-fluid">
//                         <form onSubmit={this.handleSubmit}>
//                             <div className="row" style={{ textAlign: 'start' }}>
//                                 {map(inputs, (input, key) => (
//                                     <Input
//                                         autoFocus={key === 0}
//                                         didCheckErrors={this.state.didCheckErrors}
//                                         errors={get(
//                                             this.state.errors,
//                                             [
//                                                 findIndex(this.state.errors, ['name', input.name]),
//                                                 'errors',
//                                             ],
//                                             []
//                                         )}
//                                         key={input.name}
//                                         label={input.label}
//                                         name={input.name}
//                                         onChange={this.handleChange}
//                                         placeholder={get(input, 'placeholder')}
//                                         type={input.type}
//                                         validations={{ required: true }}
//                                         value={get(this.state.value, input.name, '')}
//                                     />
//                                 ))}
//                                 <div className="col-md-12 buttonContainer">
//                                     <button className="button primary" style={{ width: '100%' }} type="submit">Submit</button>
//                                 </div>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

AuthPage.defaultProps = {};
AuthPage.propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => {
    return {
        dispatchToken: (token) => {
            return dispatch(authAction(token))
        }
    }
}

export default connect(null, mapDispatchToProps)(AuthPage);
