import React from 'react'
import './Copyright.scss'

const Copyright = () => {
    return (
        <div className="copyright">
            <div className="container">
                <p className="copyright__text">
                    Non-profit NGO European Human Rights Group, Kyiv, Ukraine, contacts: <a href="mailto:nasha@rozmova.pro">nasha@rozmova.pro</a> / Some Rights Reserved<br/>
                    Created by <a href="http://dadaweb.solutions" target="_blank" rel="noreferrer">DaDa Web Solutions</a>
                </p>
            </div>
        </div>
    )
}

export default Copyright
