import React, { memo, useState, useEffect } from 'react'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import remarkUnwrapImages from "remark-unwrap-images";
import {animateScroll} from "react-scroll";
import {useSsrEffect} from '@issr/core';

import {getArticleAction} from "../../../store/actions/getArticleAction";
import {changeActiveEdition} from "../../../store/actions/changeActiveEditionAction";
import Loader from "../../../shared/UIComponents/Loader";
import ImageRenderer from "../../components/ImageRenderer";
import avatar from '../../../assets/avatar.svg';
import './CardPage.scss';



const CardPage = ({ location }) => {
    const [source, showLink] = useState(true)
    const { pathname } = location

    const article = useSelector(({cards}) => cards.article, shallowEqual)
    const loading = useSelector(({pages}) => pages.isLoading, shallowEqual)
    const links = useSelector(({navbar}) => navbar.links, shallowEqual)

    const dispatch = useDispatch()
    useSsrEffect(() => dispatch(getArticleAction(pathname.substring(8))))

    useEffect(() => {
        animateScroll.scrollToTop()
    }, [])

    const link = links.find(item => item.title === 'Теми').arr.find(item => item.text === article.theme)
    const onShowLink = (e) => {
        if (source) {
            e.preventDefault()
        }
        showLink(!source)
    }

    if (loading) {
        return <Loader fullScreen/>
    }

    const shortDate = new Date(article._data).toLocaleDateString('UK', {year: 'numeric', month: 'numeric', day: 'numeric'})
    const datePath = shortDate.replace(/\./g, '-')
    const embedPlayer = () => { return { __html: article.SoundCloud } }
    const image = process.env.REACT_APP_URL + article.image

    return (
        <>
            <Helmet titleTemplate="%s - rozmova.pro | Це наша розмова.про важливе">
                <title>{article.title}</title>
                <meta
                    name="description"
                    content={article.shortDescription}
                />
                <meta property="og:image" content={image} />
            </Helmet>
            <div className="container">
                <div className="card--page">
                    <div className="card--page__image--container">
                        <img src={image} className="card--page__image" alt={article.title} />
                    </div>
                    {link && <NavLink
                        to={{
                            pathname: `/tema${link.to}`
                        }}
                        className='link'>
                        <h3 className="card--page__theme">{article.theme}</h3>
                    </NavLink>}
                    <NavLink to={{pathname: '/date/' + datePath, state: {date: article.date}}} className='card--link'>
                        <h4 className="card--page__info">{article.date}</h4>
                    </NavLink>
                    <h3 className="card--page__info">Переклад: {article.translator || ''}</h3>
                    {article.link && <a href={article.link} rel="noreferrer" target='_blank' className={'card--page__source--link card--page__' + (source ? 'source' : 'link')} onClick={onShowLink}>{source ? 'Подивитися джерело' : article.link}</a>}
                    <h2 className="card--page__title">{article.title}</h2>
                    <div className="card--page__player" dangerouslySetInnerHTML={embedPlayer()}></div>
                    <div className="card--page__content">
                        <ReactMarkdownWithHtml source={article.content} plugins={[remarkUnwrapImages]} allowDangerousHtml renderers={{ image: ImageRenderer }} />
                    </div>
                    {article.authorImage || article.authorDescription ? <div className="card--page__author--block">
                        <NavLink
                            onClick={() => {
                                dispatch(changeActiveEdition({ regionID: article.countryID, editionID: article.editionID }))
                            }}
                            to={{
                                pathname: '/avtor' + article.authorPathname,
                                state: { author: article.author }
                            }} className="link">
                                <img src={article.authorImage ? process.env.REACT_APP_URL + article.authorImage : avatar} alt={article.author} className="card--page__author--block__avatar" />
                        </NavLink>
                        <div className="card--page__author--block__content">
                            <NavLink
                                onClick={() => {
                                    dispatch(changeActiveEdition({ regionID: article.countryID, editionID: article.editionID }))
                                }}
                                to={{
                                    pathname: '/avtor' + article.authorPathname,
                                    state: { author: article.author }
                                }} className="link">
                                <span className="card--page__author">{article.author}</span>
                            </NavLink>
                            <ReactMarkdownWithHtml source={article.authorDescription} allowDangerousHtml transformImageUri={uri =>
                                uri.startsWith("http") ? uri : `${process.env.REACT_APP_URL}${uri}`
                            } />
                        </div>
                        <div className="clearfix"></div>
                    </div> : <NavLink
                        onClick={() => {
                            dispatch(changeActiveEdition({ regionID: article.countryID, editionID: article.editionID }))
                        }}
                        to={{
                            pathname: '/avtor' + article.authorPathname,
                            state: { author: article.author }
                        }} className="link">
                        <span className="card--page__author">{article.author}</span>
                    </NavLink>}
                    <NavLink
                        onClick={() => {
                            dispatch(changeActiveEdition({regionID: article.countryID, editionID: article.editionID}))
                        }}
                        to={{
                            pathname: `/vydannya${article.countryPathname}${article.editionPathname}`,
                            state: {to: article.editionPathname, name: article.edition, id: article.countryID}
                        }} className="card--page__edition link">
                        <img src={process.env.REACT_APP_URL + article.editionLogo} alt={article.edition} className="card--page__edition--logo"/>
                        <h3 className="card--page__edition--title">Всі публікації "{article.edition}"</h3>
                    </NavLink>
                </div>
            </div>
        </>
    )
}

export default memo(CardPage)
