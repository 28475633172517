import React, {useRef, useState} from 'react'
import search from "../../../assets/search-icon.png";
import searchWhite from "../../../assets/search-icon-white.png";
import {shallowEqual, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";


const Search = () => {
    const history = useHistory()
    const inputRef = useRef(null)
    const [value, setValue] = useState('')
    const [show, setShow] = useState(false)
    const {cards} = useSelector(({cards}) => cards, shallowEqual)
    const width = typeof window !== 'undefined' ? window.innerWidth : 0

    const showInputHandler = () => {
        setShow(!show)
        setFocus()
    }
    const onSubmit = (e) => {
        e.preventDefault()
        const valueReg = value.replace(/\s+/g, '|')

        const filterCards = cards.filter((item) => {
            const findByTitle = item.title.toLowerCase().search(valueReg) + 1
            const findByDescription = item.shortDescription.toLowerCase().search(valueReg) + 1
            // const findByDescription = item._description.toLowerCase().search(valueReg) + 1

            return !!findByTitle || !!findByDescription
        })
        setValue('')
        setShow(false)

        if (value.length) {
            history.push({
                pathname: '/search',
                state: {
                    list: filterCards,
                    searchValue: value
                }
            })
        }
    }
    const setFocus = () => inputRef.current &&  inputRef.current.focus()
    const showInputClassName = show ? 'navbar__search--field--show' : ''
    const showBlockClassName = show ? ' navbar__search--show' : ''
    const showParentClassName = show ? ' navbar__search--block--show' : ''

    return(
        <div className={'navbar__search--block' + showParentClassName}>
            <form className={'navbar__search' + showBlockClassName} onSubmit={onSubmit}>
                {!show && <img
                    onClick={showInputHandler}
                    src={width <= 768 ? searchWhite : search}
                    alt="#"
                    className="navbar__search--icon"
                />}
                {show &&
                <button type='submit' onSubmit={onSubmit} className='navbar__search--btn'>
                    <img src={width <= 768 ? searchWhite : search} alt="#" className="navbar__search--icon"/>
                </button>
                }
                <input
                    type="text"
                    autoFocus={show}
                    ref={inputRef}
                    className={"navbar__search--field " + showInputClassName}
                    value={value}
                    onChange={({target}) => setValue(target.value)}
                    onBlur={(e) => {
                        if (!e.target.classList.contains('navbar__search--field')) {
                            setShow(false)
                        }
                    }}
                />
            </form>
        </div>
    )
}

export default Search
