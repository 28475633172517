import {LOADING, NOT_LOADING} from "../types";

export const loadingAction = () => {
    return {
        type: LOADING
    }
}

export const notLoadingAction = () => {
    return {
        type: NOT_LOADING
    }
}
