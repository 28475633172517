import {IS_AUTH} from "../types";
import auth from "../../utils/auth";

const initialState = {
    token: auth.getToken() || '',
    isAuth: !!auth.getToken() || false
}

export const authReducer = (state = initialState, action) => {

    switch (action.type) {
        case(IS_AUTH):
            return {
                ...state,
                token: action.payload,
                isAuth: !!action.payload
            }
        default:
            return state
    }
}
