import React, {memo, useEffect} from 'react'
import {shallowEqual, useSelector} from "react-redux";
import {animateScroll} from "react-scroll";

import Card from "../../components/Card";
import More from "../../../shared/UIComponents/More";
import Loader from "../../../shared/UIComponents/Loader";

import "./AboutConversationPage.scss";


const AboutConversationsPage = () => {
    const width = typeof window !== 'undefined' ? window.innerWidth : 0
    const cards = useSelector(({cards}) => cards.cards, shallowEqual)
    const limit = useSelector(({cards}) => cards.limit, shallowEqual)
    const loading = useSelector(({pages}) => pages.isLoading, shallowEqual)

    useEffect(() => {
        animateScroll.scrollToTop()
    }, [])

    if (loading) {
        return <Loader fullScreen/>
    }

    return (
        <div className="container">
            <div className="main--page">
                {cards.map((item, idx) =>
                    <Card
                        limit={idx + 1 < limit}
                        cardTopStyle={{gridColumn: width > 768 ?  '1/4' : '1', gridRow: width > 768 ?  '1' : '3' }}
                        elem={width <= 768}
                        key={'card-'+item.id} {...item}/>)}
                {cards.length > 9 && (cards.length > limit) && <More limit={limit} />}
            </div>
        </div>
    )
}

export default memo(AboutConversationsPage)
