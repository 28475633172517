import {GET_CARDS} from "../types";
import auth from '../../utils/auth';
import removeMd from "remove-markdown";
import {getDate} from "../../shared/functions/getDate";
import {loadingAction, notLoadingAction} from "./loadingActions";

const createObject = (item, date, add) => {
    const thumbnail = item.image.formats && item.image.formats.thumbnail && item.image.formats.thumbnail.url
    return {
        title: item.title,
        description: item.content,
        thumbnail: thumbnail,
        image: thumbnail || item.image.url,
        date: date,
        _date: item.published_at,
        theme: item.theme.title,
        themePathname: item.theme.pathname,
        themeID: item.theme.id,
        id: item.id,
        edition: item.edition.title,
        editionID: item.edition.id,
        editionPathname: item.edition.pathname,
        country: item.country.countryName,
        countryID: item.country.id,
        countryPathname: item.country.pathname,
        author: item.author.authorName,
        pathname: item.pathname,
        SoundCloud: item.SoundCloud,
        ...add
    }
}

export const getCardAction = () => async dispatch => {
    dispatch(loadingAction())
    const { data } = await auth.axiosGet('/articles')
    const topArr = []
    const articles = data.map((item, idx) => {
        let shortDescription
        const clearDescriptionOfImage = removeMd(item.content)
        const [...descriptionArr] = clearDescriptionOfImage.split('')
        if (item.ShortDescription) {
            shortDescription = item.ShortDescription
        } else if (clearDescriptionOfImage.length > 230) {
            shortDescription = descriptionArr.map((item, idx) => idx + 1 <= 130 ? item : null).join('') + '...'
        } else {
            shortDescription = descriptionArr.join('')
        }

        const date = getDate(item)
        if (item.top) {
            topArr.push(idx)
        }
        return item && createObject(item, date, {top: item.top, _description: clearDescriptionOfImage, shortDescription})
    })
    topArr.pop()
    topArr.forEach(idx => articles[idx].top = false)

    dispatch({
        type: GET_CARDS,
        payload: articles
    })
    dispatch(notLoadingAction())
}
