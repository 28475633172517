import React, { memo, useState, useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {NavLink, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
import {animateScroll} from "react-scroll";

import Card from "../../components/Card";
import More from "../../../shared/UIComponents/More";
import Loader from "../../../shared/UIComponents/Loader";
import { getEditionsActions } from '../../../store/actions/getEditionsAction';
import {changeActiveEdition} from "../../../store/actions/changeActiveEditionAction";

import './EditionPage.scss'


const EditionPage = ({name, to, id}) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const {editions} = useSelector(({lists}) => lists, shallowEqual)
    const cards = useSelector(({cards}) => cards.cards, shallowEqual)
    const limit = useSelector(({cards}) => cards.limit, shallowEqual)
    const loading = useSelector(({pages}) => pages.isLoading, shallowEqual)
    const [hover, setHover] = useState('')

    useEffect(() => {
        dispatch(getEditionsActions())
    }, [dispatch])

    useEffect(() => {
        animateScroll.scrollToTop()
    }, [])

    if (loading) {
        return <Loader fullScreen/>
    }

    const activeEditions = editions
        && editions.find(item => {
            return item.id === id
        })
    const activeEdition = activeEditions
        && activeEditions.edition.find(({pathname}) => pathname === '/' + location.pathname.split('/').pop())

    const filterCards = cards.filter(item => {
        return activeEdition && (item.edition === activeEdition.title
            || (activeEdition.title === 'Усі Видання' && item.country === activeEditions.nameCountry))
    })

    return activeEditions ? (
        <>
            <Helmet titleTemplate="%s - rozmova.pro | Це наша розмова.про важливе">
                <title>
                    {activeEdition.title === 'Усі Видання' ? activeEditions.nameCountry + ' - Усі видання' : activeEdition.title}
                </title>
                <meta
                    name="description"
                    content={
                        activeEdition.description
                            || 'Некомерційний проєкт, який ставить собі за мету інтелектуальну протидію популізму' +
                            'шляхом перекладу важливих аналітичних матеріалів авторитетних західних видань.'
                    }
                />
                <meta property="og:image" content={
                    activeEdition && activeEdition.logo
                        ? process.env.REACT_APP_URL + activeEdition.logo.url
                        : (
                            activeEditions.flag.includes('http')
                                ? activeEditions.flag
                                : process.env.REACT_APP_URL + activeEditions.flag
                        )
                } />
            </Helmet>
            <div className="container">
                <div className="edition--page">
                    <div className="edition--page__name">
                        <h3 className="edition--page__name--title">{activeEditions.nameCountry}</h3>
                    </div>
                    <div onMouseEnter={() => setHover(' hover')} onMouseLeave={() => setHover('')} className={'edition--page__list' + hover}>
                        <h3 className="edition--page__list--title">{activeEdition && activeEdition.title}</h3>
                        <div className="edition--page__list--hide">
                            {activeEditions && activeEditions.edition.map((item, idx) => {
                                const activeClass = '/vydannya' + item.pathname === location.pathname && 'active--class'
                                return <NavLink
                                    onClick={() => {
                                        dispatch(changeActiveEdition({ regionID: activeEditions.id, editionID: item.id }))
                                        setHover('')
                                    }}
                                    key={'edition-'+item.id}
                                    exact
                                    to={{
                                        pathname: '/vydannya' + (idx ?
                                            `/${activeEditions._nameCountry}` + item.pathname
                                            : `/${activeEditions._nameCountry}`),
                                        state: {name, to, id}
                                    }}
                                    className={'edition--page__list--hide--link link ' + activeClass}
                                    activeClassName='active--class'
                                >{item.title}</NavLink>
                            })}
                        </div>
                    </div>
                    <div className="edition--page__container">
                        {activeEdition && (activeEdition.pathname !== activeEditions.pathname) &&
                        <Card
                            cardTopContainerStyle={{flexDirection: 'column-reverse'}}
                            editionTop={activeEdition}
                            flag={activeEditions.flag}
                        />}
                        {filterCards.map((item, idx) => {
                            const {top, ...newItem} = {...item}
                            return <Card
                                limit={idx + 1 < limit}
                                id={'card-' + item.id}
                                key={'card-' + item.id}
                                {...newItem}
                            />
                        })
                        }
                        {filterCards.length > 9 && (filterCards.length > limit) && <More limit={limit} style={{marginTop: '10px'}}/>}
                    </div>
                </div>
            </div>
        </>
    ) : <></>
}

export default memo(EditionPage)
