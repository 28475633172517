import {GET_LINKS} from "../types";
import auth from '../../utils/auth';

export const getLinksAction = (payload) => async dispatch => {
    const { data } = await auth.axiosGet('/headings')
    const themes = data.map(item => ({
        text: item.title,
        to: item.pathname,
        id: item.id
    }))

    const { data: DATA } = await auth.axiosGet('/regions')
    const countries = DATA.map(item => ({
        text: item.countryName,
        to: item.pathname,
        flag: item.flag.url,
        id: item.id,
    }))

    const { data: pagesData } = await auth.axiosGet('/pages')
    const pages = pagesData.map(item => ({
        ...item,
        pre: item.pathname,
        static: true
    }))

    dispatch({
        type: GET_LINKS,
        payload: {
            themes,
            countries,
            pages
        }
    })
}
