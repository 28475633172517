import React, {useEffect} from 'react'
import {shallowEqual, useSelector} from "react-redux";
import {animateScroll} from "react-scroll";

import Card from "../../components/Card";
import Loader from "../../../shared/UIComponents/Loader";

import './DateArticlesPage.scss';

const DateArticlesPage = ({date}) => {
    const cards = useSelector(({cards}) => cards.cards, shallowEqual)
    const loading = useSelector(({pages}) => pages.isLoading, shallowEqual)
    const cardsWithOutTop = cards.map(item => ({...item, top: false}))
    const [shortDate] = date.split(',')
    const filterCards = cardsWithOutTop.filter(item => {
        return item.date.includes(shortDate)
    })

    useEffect(() => {
        animateScroll.scrollToTop()
    }, [])

    if (loading) {
        return (
            <div className="container">
                <Loader />
            </div>
        )
    }

    return(
        <div className="container">
            <div className="date__cards">
                {filterCards.map(item => <Card {...item} />) }
            </div>
        </div>
    )
}

export default DateArticlesPage
