import React from 'react'
import { combineReducers } from "redux";
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import { listsReducer } from "./reducers/listsReducer";
import { navbarReducer } from "./reducers/navbarReducer";
import { cardReducer } from "./reducers/cardReducer";
import { pageReducer } from "./reducers/pageReducer";
import { productsReducer } from "./reducers/productsReducer";
import { authReducer } from "./reducers/authReducer";

const rootReducer = combineReducers({
    lists: listsReducer,
    navbar: navbarReducer,
    cards: cardReducer,
    pages: pageReducer,
    products: productsReducer,
    auth: authReducer
})

const store = ({ initState = {} } = {}) => {
    const middleware = getDefaultMiddleware({
        immutableCheck: true,
        serializableCheck: true,
        thunk: true
    });
  
    const store = configureStore({
        reducer: rootReducer,
        middleware: middleware.concat([]),
        preloadedState: initState
    });
  
    return { store };
};

export default store;
